import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/gift/:code',
    name: 'Gift',
    component: () => import(/* webpackChunkName: "gift" */ '../views/Gift.view.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.view.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
