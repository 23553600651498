<template>
  <router-view/>
</template>

<style lang="scss">
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: $font-Primary;
    font-size: 62.5%;
  }
</style>
